import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { configureStore } from 'store/configureStore';

import { PublicDisplaysPage } from '../pages/PublicDisplays/PublicDisplaysPage';

const publicDisplaysPage = document.getElementById('public-displays-page') as HTMLElement;

const store = configureStore();

if (publicDisplaysPage) {
  ReactDOM.render(
    <Provider store={store}>
      <PublicDisplaysPage />
    </Provider>,
    publicDisplaysPage,
  );
}
